import { IAuthPieceState } from "../pages/auth-piece";
import { isNotEmpty } from "./is-not-empty";

export const decideQuerySeparator = (uri: string): string => {
  if (uri.includes("?")) {
    return "&";
  }
  return "?";
};

export const buildQuery = (state: IAuthPieceState) => {
  let query = `client_id=${state?.clientId}&country=${state?.country}&company=${state?.company}&language=${state?.language}&redirect_uri=${state?.redirectUri}`;

  if (isNotEmpty(state?.username)) {
    query = query.concat(`&username=${state.username}`);
  }
  if (isNotEmpty(state?.session)) {
    query = query.concat(`&session=${state.session}`);
  }
  if (isNotEmpty(state?.destination)) {
    query = query.concat(`&destination=${state.destination}`);
  }
  if (isNotEmpty(state?.previousPage)) {
    query = query.concat(`&previousPage=${state.previousPage}`);
  }
  if (isNotEmpty(state?.code)) {
    query = query.concat(`&code=${state.code}`);
  }

  if (isNotEmpty(state?.maintenance)) {
    query = query.concat(`&maintenance=${state.maintenance}`);
  }
  if (isNotEmpty(state?.errorDescription)) {
    query = query.concat(`&error_description=${state.errorDescription}`);
  }
  if (isNotEmpty(state?.responseType)) {
    query = query.concat(`&response_type=${state.responseType}`);
  }
  if (isNotEmpty(state?.codeChallenge)) {
    query = query.concat(`&code_challenge=${state.codeChallenge}`);
  }
  if (isNotEmpty(state?.codeChallengeMethod)) {
    query = query.concat(`&code_challenge_method=${state.codeChallengeMethod}`);
  }
  if (isNotEmpty(state?.state)) {
    query = query.concat(`&state=${state.state}`);
  }
  return query;
};
